// constants
import { TABS } from "../../constants";
import { useTranslation } from "react-i18next";

export interface MenuItemType {
  id: number;
  key: string;
  icon: string;
  tooltipTitle: string;
  className?: string;
  tabId: TABS.USERS | TABS.NEW_ACCOUNT | TABS.CHAT;
  // | TABS.BOOKMARK
  // | TABS.CALLS
  //;
  // | TABS.CONTACTS
  // | TABS.SETTINGS
}

const getMenuItems = (t: any): MenuItemType[] => [
  // {
  //   id: 7,
  //   key: "pills-new-account-tab",
  //   icon: "bx bx-plus",
  //   tooltipTitle: t("new_account"),
  //   tabId: TABS.NEW_ACCOUNT,
  // },
  {
    id: 2,
    key: "pills-chat-tab",
    icon: "bx bx-conversation",
    tooltipTitle: t("chat"),
    tabId: TABS.CHAT,
  },
  // {
  //   id: 3,
  //   key: "pills-contacts-tab",
  //   icon: "bx bxs-user-detail",
  //   tooltipTitle: t("contacts"),
  //   tabId: TABS.CONTACTS,
  // },
  // {
  //   id: 4,
  //   key: "pills-calls-tab",
  //   icon: "bx bx-phone-call",
  //   tooltipTitle: t("calls"),
  //   tabId: TABS.CALLS,
  // },
  // {
  //   id: 5,
  //   key: "pills-bookmark-tab",
  //   icon: "bx bx-bookmarks",
  //   tooltipTitle: t("bookmarks"),
  //   tabId: TABS.BOOKMARK,
  // },
  // {
  //   id: 6,
  //   key: "pills-setting-tab",
  //   icon: "bx bx-cog",
  //   tooltipTitle: t("settings"),
  //   tabId: TABS.SETTINGS,
  // },
];

export { getMenuItems };
