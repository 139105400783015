import React from "react";
import { Collapse, IconButton, Box } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTranslation } from "react-i18next";

interface MoreMenuProps {
  isOpen: boolean;
  onSelectImages: (images: Array<any>) => void;
  onToggle: () => any;
  onSelectFiles: (files: Array<any>) => void;
}

const MoreMenu = ({
  isOpen,
  onSelectImages,
  onToggle,
  onSelectFiles,
}: MoreMenuProps) => {
  const { t } = useTranslation();

  const handleSelectImages = (e: any) => {
    const files = [...e.target.files];
    if (files) {
      onSelectImages(files);
      onToggle();
    }
  };

  const handleSelectFiles = (e: any) => {
    const files = [...e.target.files];
    if (files) {
      onSelectFiles(files);
      onToggle();
    }
  };

  return (
    <Collapse in={isOpen} className="chat-input-collapse">
      <Box display="flex" justifyContent="space-around" paddingY={2}>
        <IconButton component="label">
          <input type="file" hidden onChange={handleSelectFiles} multiple />
          <AttachFileIcon />
        </IconButton>
        <IconButton component="label">
          <input
            type="file"
            hidden
            accept="image/png, image/jpeg"
            onChange={handleSelectImages}
            multiple
          />
          <PhotoLibraryIcon />
        </IconButton>
        <IconButton>
          <CameraAltIcon />
        </IconButton>
        <IconButton>
          <LocationOnIcon />
        </IconButton>
      </Box>
    </Collapse>
  );
};

export default MoreMenu;
