import React, { useState, useEffect, useCallback, useRef } from 'react';
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Container
} from 'reactstrap';
import { Tooltip as ReactTooltip } from "react-tooltip";

import Joyride, { Step, CallBackProps } from 'react-joyride';

// hooks
import { useRedux } from "../../hooks/index";

// components
import StatusPage from "./Profile/StatusPage";
import JessPage from "./Profile/JessPage";
import FixedIcon from "./Ai/FixedIcons";
import { URLS } from "../../constants";
import axios from "axios";
import { Session } from "./types";

const Index: React.FC = () => {
  const { useAppSelector } = useRedux();
  const { t } = useTranslation();
  const errorData = (state: any) => ({
    selectedChat: state.Chats.selectedChat,
    userProfile: state.Profile.userProfile,
  });
  const { selectedChat, userProfile } = useAppSelector(errorData);
  const [isAIEnabled, setIsAIEnabled] = useState<boolean>(false);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Estado para loading
  const [activeTab, setActiveTab] = useState("2");
  const apiKey = Cookies.get("api-key");
  const axiosInstance = axios.create({
    baseURL: URLS.CLIENT_URL,
    headers: {
      "Content-Type": "application/json",
      APIKEY: apiKey,
    },
  });

  const statusButtonRef = useRef<HTMLButtonElement>(null);
  const iaButtonRef = useRef<HTMLDivElement>(null);

  const [runTour, setRunTour] = useState(false);

  const [steps, setSteps] = useState<Step[]>([
    {
      target: '.tab-jess',
      content: t("tourJoyride.jessArea"),
      disableBeacon: true
    },
    {
      target: '.tab-sessions',
      content: t("tourJoyride.customer"),
      disableBeacon: true,
    },
  ]);

  const [tooltipsVisible, setTooltipsVisible] = useState(false);

  useEffect(() => {
    try {
      const tooltipsSeen = localStorage.getItem("tooltipsSeen");
      setTooltipsVisible(!tooltipsSeen);
    } catch (error) {
      console.warn("ResizeObserver error suppressed:", error);
    }
  }, []);

  const disableTooltips = () => {
    localStorage.setItem("tooltipsSeen", "true");
    setTooltipsVisible(false);
  };

  useEffect(() => {
    const newSteps = [...steps];

    if (iaButtonRef.current && !steps.some(step => step.target === iaButtonRef.current)) {
      newSteps.unshift({
        target: iaButtonRef.current as HTMLElement,
        content: t("tourJoyride.iaButton"),
        disableBeacon: true,
      });
    }

    if (statusButtonRef.current && !steps.some(step => step.target === statusButtonRef.current)) {
      newSteps.unshift({
        target: statusButtonRef.current as HTMLElement,
        content: t('tourJoyride.sessionButtonAdd'),
        disableBeacon: true,
      });
    }

    if (newSteps.length > steps.length) {
      setSteps(newSteps);
    }
  }, [statusButtonRef, iaButtonRef, steps, t]);


  useEffect(() => {
    const hasSeenTour = Cookies.get('has_seen_tour');

    const fetchStatus = async () => {
      try {
        const response = await axiosInstance.get("/api/get-status");
        const result = response.data;

        const sessions = Array.isArray(result.session_status)
          ? result.session_status.map((session: any) => ({
            ...session,
            phoneNumber: session.phone_number || ""
          }))
          : [];

        setSessions(sessions);
        setIsAIEnabled(result.isAIEnabled);

        if (!hasSeenTour && sessions.length === 0) {
          setRunTour(true);
        }
      } catch (error) {
        console.error("Failed to fetch status:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatus();

  }, []);

  const activeTour = () => {
    setRunTour(true);
  };

  const userRole = userProfile?.role || "admin";

  const updateSessions = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/api/get-status");
      const result = response.data;

      if (
        result.session_status &&
        Array.isArray(result.session_status) &&
        result.session_status.length > 0
      ) {
        setSessions(result.session_status);
      } else {
        setSessions([]);
        console.error("session_status is not an array or empty:", result);
      }
    } catch (error) {
      console.error("Failed to update sessions:", error);
    }
  }, [axiosInstance]);

  const handleToggleAI = async () => {
    const newStatus = !isAIEnabled;
    try {
      await axiosInstance.post("/api/toggle-ai", { isAIEnabled: newStatus });
      setIsAIEnabled(newStatus);
    } catch (error) {
      console.error("Failed to update toggle status:", error);
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if (status === 'finished' || status === 'skipped') {
      setRunTour(false);
      Cookies.set('has_seen_tour', 'true', { expires: 365 });
    }
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const jessSessions = sessions.filter(session => session.belongs_to_ai);
  const customersSessions = sessions.filter(session => !session.belongs_to_ai);

  return (
    <>
      <Joyride
        steps={steps}
        run={runTour}
        continuous
        scrollToFirstStep
        showProgress
        disableOverlayClose={true}
        disableOverlay={false}
        showSkipButton
        callback={handleJoyrideCallback}
        locale={{
          back: t("tourJoyride.Back"),
          close: t("tourJoyride.Close"),
          last: t("tourJoyride.Last"),
          next: t("tourJoyride.Next"),
          skip: t("tourJoyride.Skip"),
        }}
        styles={{
          options: {
            primaryColor: '#6E00C4',
            zIndex: 10000,
          },
        }}
      />
      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-chat-show": selectedChat,
        })}
        id="user-chat"
      >
        <div className="user-chat-overlay" id="user-chat-overlay"></div>

        <div className="max-card-area">
          <Container className="card-status">
            <Card className="big-card container-card">
              <CardBody>
                <Nav tabs className="mb-3 justify-content-center">
                  <NavItem className="cursor-pointer">
                    <NavLink
                      id="assistantTab"
                      className={classnames("tab-jess", { active: activeTab === "1" })}
                      onClick={() => toggleTab("1")}
                      data-tooltip-id="assistantTooltip"
                    >
                      {t("Assistant")}
                    </NavLink>
                    {tooltipsVisible && (
                      <ReactTooltip
                        id="assistantTooltip"
                        place="top"
                        clickable
                        className="custom-tooltip"
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <p style={{margin: 0, fontSize: 14}}>{t("assistant_tooltip")}</p>
                          <button
                            onClick={disableTooltips}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: "12px",
                              background: "none",
                              border: "none",
                              color: "red",
                              cursor: "pointer",
                              fontSize: "8px"
                            }}
                          >
                            {t("NÃO MOSTRAR MAIS")}
                          </button>
                        </div>
                      </ReactTooltip>
                    )}
                  </NavItem>

                  <NavItem className="cursor-pointer">
                    <NavLink
                      id="sessionsTab"
                      className={classnames("tab-sessions", { active: activeTab === "2" })}
                      onClick={() => toggleTab("2")}
                      data-tooltip-id="sessionsTooltip"
                    >
                      {t("Sessions")}
                    </NavLink>
                    {tooltipsVisible && (
                      <ReactTooltip
                        id="sessionsTooltip"
                        place="top"
                        clickable
                        className="custom-tooltip"
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <p style={{ margin: 0, fontSize: 14 }}>{t("sessions_tooltip")}</p>
                          <button
                            onClick={disableTooltips}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: "12px",
                              background: "none",
                              border: "none",
                              color: "red",
                              cursor: "pointer",
                              fontSize: "8px"
                            }}
                          >
                            {t("NÃO MOSTRAR MAIS")}
                          </button>
                        </div>
                      </ReactTooltip>
                    )}
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <JessPage sessions={jessSessions} role={userRole}/>
                  </TabPane>
                  <TabPane tabId="2">
                    <StatusPage
                      ref={statusButtonRef}
                      sessions={customersSessions}
                      role={userRole}
                      updateSessions={updateSessions}
                      loadingSearch={loading}
                      activeTour={activeTour}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Container>
        </div>
          <FixedIcon ref={iaButtonRef} isAIEnabled={isAIEnabled} toggleAI={handleToggleAI}/>
        </div>
      </>
      );
      };

      export default Index;
