import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, List, ListItem } from "@mui/material";

// interface
import { UserTypes } from "../../../data";

// component
import ChatUser from "./ChatUser";

interface ChatListProps {
  users: Array<UserTypes>;
  selectedChat: string | number;
  onSelectChat: (id: number | string) => void;
}

const ChatList = ({ users, selectedChat, onSelectChat }: ChatListProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        variant="caption"
        sx={{ fontWeight: 500, color: "text.secondary", px: 2, pt: 2 }}
      >
        {t("Recent")}
      </Typography>

      <List disablePadding>
        {users.map((user: UserTypes, key: number) => (
          <ListItem key={key} disableGutters>
            <ChatUser
              user={user}
              selectedChat={selectedChat}
              onSelectChat={onSelectChat}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ChatList;
