import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/system";

const CustomTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#4e3699",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#4e3699",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ccc",
    },
    "&:hover fieldset": {
      borderColor: "#4e3699",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4e3699",
    },
  },
});

export default CustomTextField;
