import React from 'react';
import {FaExternalLinkAlt} from "react-icons/fa";
import jessIcon from "../../../assets/images/jess.png";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button
} from "reactstrap";
import {useTranslation} from "react-i18next";
import emptyStateImage from "../../../assets/images/empty.svg";
import "../../../assets/scss/custom/pages/_status-page.scss";
import {Typewriter} from 'react-simple-typewriter';
import { Session } from "../types";

interface StatusPageProps {
  role: string;
  sessions: Session[];
}

const JessPage: React.FC<StatusPageProps> = ({sessions, role}) => {
  const {t} = useTranslation();

  const formatPhoneNumber = (phoneNumber: string): string => {
    const countryCode = "55";
    const numberWithoutCountryCode = phoneNumber.slice(2);
    const areaCode = numberWithoutCountryCode.slice(0, 2);
    const mainNumber = numberWithoutCountryCode.slice(2);

    const formattedNumber =
      mainNumber.length === 8
        ? `${mainNumber.slice(0, 4)}-${mainNumber.slice(4)}`
        : `${mainNumber.slice(0, 5)}-${mainNumber.slice(5)}`;

    return `+${countryCode} ${areaCode} ${formattedNumber}`;
  };

  return (
    <Container>
      <Row className="justify-content-center">
        {sessions.length > 0 ? (
          sessions.map((session, index) => (
            <Col xs="12" md="8" key={index} className="mb-4">
              <Card>
                <CardBody className="d-flex align-items-center justify-content-center pb-4 flex-column">
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "100%",
                      border: session.status === "WORKING" ? "solid 4px #06d6a0" : "solid 4px #dc3545"
                    }}
                    className="d-flex justify-content-center flex-column align-items-center mb-3"
                  >
                    <img
                      src={jessIcon}
                      alt="WhatsApp Icon"
                      style={{width: "70px", height: "70px", marginBottom: "10px"}}
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-end align-items-center text-center">
                    <p style={{margin: 0, fontSize: "25px"}}>Jess</p>
                    {session.phone_number ? (
                      <p
                        style={{
                          fontSize: "15px",
                          color: "#6c757d",
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center"
                        }}
                        rel="noopener noreferrer"
                      >
                        {formatPhoneNumber(session.phone_number)}
                      </p>
                    ) : (
                      <div style={{fontSize: "20px", color: "#6c757d"}}>
                        {t("message_phone_unavailable")}
                      </div>
                    )}
                    <p style={{
                      fontSize: "18px",
                      color: "#6c757d",
                      textDecoration: "none",
                    }}>
                      {
                        session.status === "WORKING"
                          ?
                          <Typewriter
                            words={[
                              t("how_can_i_help_you"), t("customize_communication"),
                              t("tone_of_voice"), t("language_style"), t("essential_information"), t("information_to_avoid"), t("customized_communication_guide")
                            ]}
                            loop={true}
                            cursor
                            cursorStyle='_'
                            typeSpeed={70}
                            deleteSpeed={10}
                            delaySpeed={3000}
                          />
                          :
                          t("message_session_no_working")
                      }

                    </p>
                    {session.status === "WORKING" &&
                      <Button style={{background: "#4e36a0"}} target="_blank" href={`https://wa.me/${session.phone_number.replace(/\D/g, '')}`} className="mt-3">
                        {t("talk_with_me_in_whatsapp")}
                        <FaExternalLinkAlt style={{ marginLeft: "8px", fontSize: "14px" }} />
                      </Button>
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))
        ) : (
          <div className="text-center my-5">
            <img
              src={emptyStateImage}
              alt={t("No sessions yet")}
              style={{width: "150px", marginBottom: "20px"}}
            />
            <h5 className="subtitle">Error</h5>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default JessPage;
