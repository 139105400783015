import React from "react";
import { IconButton, Tooltip, useMediaQuery, Theme } from "@mui/material";
import { MdOutlineSupportAgent } from "react-icons/md";
import { LINKS } from "../constants/settings";
import { useTranslation } from "react-i18next";

const FloatingSupportIcon: React.FC = () => {
  const { t } = useTranslation();

  const handleSupportClick = () => {
    const whatsappUrl = `${LINKS.WHATSAPP_SUPPORT}&text=${encodeURIComponent(
      t("whatsapp_support_message"),
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <Tooltip title={t("support_tooltip")} placement="right" arrow>
      <IconButton
        onClick={handleSupportClick}
        sx={{
          color: "#FFF",
          borderRadius: "50%",
        }}
      >
        <MdOutlineSupportAgent  size="2.3rem" />
      </IconButton>
    </Tooltip>
  );
};

export default FloatingSupportIcon;
