import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import { FaRobot } from "react-icons/fa";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Tooltip,
  Switch,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import "./FixedIcon.scss";

interface FixedIconProps {
  isAIEnabled: boolean;
  toggleAI: () => void;
}

const FixedIcon = forwardRef<HTMLDivElement, FixedIconProps>(({ isAIEnabled, toggleAI }, ref) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(true);
  const [showStatus, setShowStatus] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowStatus(false);
    }, 20000); //3s

    return () => clearTimeout(timer);
  }, []);

  const buttonRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => buttonRef.current as HTMLDivElement);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const closeTooltip = () => {
    setShowTooltip(false);
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
          {showStatus && (
            <div className="status-text">
              <span style={{ color: isAIEnabled ? "green" : "red" }}>
                  {isAIEnabled ? t("ai_enabled") : t("ai_disabled")}
              </span>
            </div>
          )}
          <div ref={buttonRef} className="fixed-icon" onClick={toggleModal}>
            <FaRobot size={30} color={isAIEnabled ? "green" : "red"} />
          </div>
      </div>
      <Dialog
        open={modalOpen}
        onClose={toggleModal}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "20px",
            padding: "20px",
          },
        }}
      >
        <DialogTitle>
          {t("ai_control")}
          <IconButton
            aria-label="close"
            onClick={toggleModal}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers style={{ padding: "20px" }}>
          <FormControlLabel
            control={
              <Switch
                checked={isAIEnabled}
                onChange={toggleAI}
                color="primary"
              />
            }
            label={isAIEnabled ? t("disable_ai") : t("enable_ai")}
            style={{ marginLeft: 0 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={toggleModal}
            variant="contained"
            style={{
              backgroundColor: "#4e3699",
              borderRadius: "20px",
              color: "white",
              textTransform: "none",
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default FixedIcon;
