import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export const CookieConsent = () => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const consent = Cookies.get("cookie-consent");
    if (!consent) {
      setModal(true);
    }
  }, []);

  const acceptCookies = () => {
    Cookies.set("cookie-consent", "accepted", { expires: 365 });
    setModal(false);
  };

  const declineCookies = () => {
    setModal(false);
  };

  return (
    <Modal isOpen={modal} toggle={declineCookies}>
      <ModalHeader style={{ maxHeight: "50px" }} toggle={declineCookies}>
        <span style={{ fontSize: "25px" }}>
          {t("cookieConsent.header")}
        </span>
      </ModalHeader>
      <ModalBody>{t("cookieConsent.body")}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={acceptCookies}>
          {t("cookieConsent.acceptButton")}
        </Button>{" "}
        <Button color="secondary" onClick={declineCookies}>
          {t("cookieConsent.declineButton")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
