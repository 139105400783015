import React from "react";
import {
  Box,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { FormProvider, Controller } from "react-hook-form";
import axios from "axios";
import { URLS } from "../../constants";
import CustomTextField from "./CustomTextField";
import { useCompanyForm } from "../../hooks/useCompanyForm";
import { CustomStepConnector, CustomStepIcon } from "./CustomStepConnector";
import { useTranslation } from "react-i18next";

interface CompanyRegisterProps {
  onSuccess: () => void;
  onError: () => void;
}

const CompanyRegister: React.FC<CompanyRegisterProps> = ({ onSuccess, onError }) => {
  const { methods, steps, activeStep, handleNext, handleBack } =
    useCompanyForm();
  const { t } = useTranslation();

  const handleSubmit = async (data: any) => {
    const modifiedData = {
      ...data,
      telephone: data.telephone ? [data.telephone] : [],
    };

    try {
      const response = await axios.post(
        `${URLS.CLIENT_URL}/api/signup-company`,
        modifiedData,
      );
      console.log("Cadastro de empresa realizado:", response.data);
      onSuccess();
    } catch (error) {
      console.error("Erro no cadastro de empresa:", error);
      onError();
    }
  };

  return (
    <Box
      sx={{ width: "100%", maxHeight: "80vh", overflowY: "auto", padding: 2 }}
    >
      <Typography variant="h5" align="center" gutterBottom>
        {t("companyRegister.title")}
      </Typography>
      <Stepper
        activeStep={activeStep}
        sx={{ marginBottom: 4 }}
        connector={<CustomStepConnector />}
        alternativeLabel
      >
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              sx={{
                [`& .MuiStepLabel-label`]: {
                  color:
                    activeStep === index ? "#4e3699" : "rgba(0, 0, 0, 0.38)",
                  fontSize: { xs: "0.75rem", md: "1rem" },
                },
                [`& .MuiStepLabel-label.Mui-completed`]: {
                  color: "#4e3699",
                },
              }}
            >
              {`${index + 1}. ${t(`companyRegister.steps.${index}`)}`}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(
            handleSubmit,
            (errors) => {
              console.log('Erros de Validação:', errors);
            }
          )}
        >
          {activeStep === 0 && (
            <>
              <Controller
                name="name"
                control={methods.control}
                defaultValue=""
                rules={{ required: t("companyRegister.fields.nameRequired") }}
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.name")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="fantasy_name"
                control={methods.control}
                defaultValue=""
                rules={{
                  required: t("companyRegister.fields.fantasyNameRequired"),
                }}
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.fantasy_name")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="cnpj"
                control={methods.control}
                defaultValue=""
                rules={{
                  required: t("companyRegister.fields.cnpjRequired"),
                  pattern: {
                    value: /^\d{14}$/, // Validação de CNPJ
                    message: t("companyRegister.fields.cnpjInvalid"),
                  },
                }}
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.cnpj")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="area"
                control={methods.control}
                defaultValue=""
                rules={{ required: t("companyRegister.fields.areaRequired") }}
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.area")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
            </>
          )}

          {activeStep === 1 && (
            <>
              <Controller
                name="telephone"
                control={methods.control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.telephone")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="email"
                control={methods.control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.email")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="website"
                control={methods.control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.website")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="instagram_profile"
                control={methods.control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.instagram_profile")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
            </>
          )}

          {activeStep === 2 && (
            <>
              <FormControl fullWidth margin="normal">
                <InputLabel>
                  {t("companyRegister.fields.subscription")}
                </InputLabel>
                <Controller
                  name="subscription"
                  control={methods.control}
                  defaultValue="BASIC"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t("companyRegister.fields.subscription")}
                    >
                      <MenuItem value="BASIC">BASIC</MenuItem>
                      <MenuItem value="GOLD">GOLD</MenuItem>
                      <MenuItem value="PREMIUM">PREMIUM</MenuItem>
                      <MenuItem value="ON DEMAND">ON DEMAND</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <InputLabel>
                  {t("companyRegister.fields.subscription_message")}
                </InputLabel>
                <Controller
                  name="subscription_message"
                  control={methods.control}
                  defaultValue="2K"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t("companyRegister.fields.subscription_message")}
                    >
                      <MenuItem value="2K">2K</MenuItem>
                      <MenuItem value="6K">6K</MenuItem>
                      <MenuItem value="10K">10K</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>

              <Controller
                name="address.zip_code"
                control={methods.control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.zip_code")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="address.street"
                control={methods.control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.street")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="address.number"
                control={methods.control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.number")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="address.city"
                control={methods.control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.city")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="address.state"
                control={methods.control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.state")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="address.country"
                control={methods.control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("companyRegister.fields.country")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
            </>
          )}

          <Box display="flex" justifyContent="space-between" mt={4}>
            <Button
              variant="outlined"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{ color: "#4e3699", borderColor: "#4e3699" }}
            >
              {t("companyRegister.backButton")}
            </Button>
            <Button
              variant="contained"
              type={activeStep === 3 ? "submit" : "button"}
              sx={{
                backgroundColor: "#4e3699",
                color: "#fff",
                "&:hover": { backgroundColor: "#60bfb7" },
              }}
              onClick={activeStep === 3 ? undefined : handleNext}
            >
              {activeStep === 2
                ? t("companyRegister.submitButton")
                : t("companyRegister.nextButton")}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default CompanyRegister;
