import { STATUS_TYPES } from "../constants";

export const mockUsers = [
  {
    id: "1",
    firstName: "Marguerite",
    lastName: "Campbell",
    profileImage: null,
    status: STATUS_TYPES.ACTIVE,
    email: "marguerite.campbell@example.com",
    location: "California, USA",
    meta: {
      unRead: 3,
      messagePreview: "Última mensagem de exemplo",
      lastMessageTime: "9:04",
      lastMessageSent: true,
      lastMessageRead: true,
    },
  },
  {
    id: "2",
    firstName: "John",
    lastName: "Doe",
    profileImage: null,
    status: STATUS_TYPES.AWAY,
    email: "john.doe@example.com",
    location: "New York, USA",
    meta: {
      unRead: 1,
      messagePreview: "Outra prévia de mensagem",
      lastMessageTime: "8:45",
      lastMessageSent: true,
      lastMessageRead: true,
    },
  },
  {
    id: "3",
    firstName: "Alice",
    lastName: "Johnson",
    profileImage: null,
    status: STATUS_TYPES.DO_NOT_DISTURB,
    email: "alice.johnson@example.com",
    location: "Texas, USA",
    meta: {
      unRead: 0,
      messagePreview: "Mais uma prévia de mensagem",
      lastMessageTime: "8:30",
      lastMessageSent: false,
      lastMessageRead: false,
    },
  },
];

export const mockConversations = [
  {
    conversationId: "101",
    userId: "1",
    messages: [
      {
        mId: 1,
        text: "Good morning 😊",
        time: "2024-10-22T09:00:00",
        meta: {
          receiver: "2",
          sender: "1",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "How are you?",
        time: "2024-10-22T09:05:00",
        meta: {
          receiver: "1",
          sender: "2",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: "102",
    userId: "2",
    messages: [
      {
        mId: 1,
        text: "Hey John, are you coming to the meeting?",
        time: "2024-10-21T18:00:00",
        meta: {
          receiver: "2",
          sender: "1",
          sent: true,
          received: true,
          read: false,
        },
      },
      {
        mId: 2,
        text: "Yes, I'll be there.",
        time: "2024-10-21T18:15:00",
        meta: {
          receiver: "1",
          sender: "2",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
  {
    conversationId: "103",
    userId: "3",
    messages: [
      {
        mId: 1,
        text: "Did you finish the report?",
        time: "2024-10-21T08:00:00",
        meta: {
          receiver: "3",
          sender: "1",
          sent: true,
          received: true,
          read: true,
        },
      },
      {
        mId: 2,
        text: "I'm almost done, sending it in a few minutes.",
        time: "2024-10-21T08:10:00",
        meta: {
          receiver: "1",
          sender: "3",
          sent: true,
          received: true,
          read: true,
        },
      },
    ],
  },
];

export default { mockUsers, mockConversations };
