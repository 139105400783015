import React from "react";
import { Link } from "react-router-dom";
import { Box, Avatar, Badge, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// interface
import { ChannelTypes } from "../../../data/chat";

interface ChannelProps {
  channel: ChannelTypes;
  selectedChat: string | number;
  onSelectChat: (id: number | string, isChannel?: boolean) => void;
}

const ChatChannel = ({ channel, selectedChat, onSelectChat }: ChannelProps) => {
  const { t } = useTranslation();
  const unRead = channel.meta && channel.meta.unRead;
  const isSelectedChat: boolean =
    selectedChat && selectedChat === channel.id ? true : false;
  const onClick = () => {
    onSelectChat(channel.id, true);
  };

  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        p: 2,
        backgroundColor: isSelectedChat ? "action.selected" : "transparent",
        cursor: "pointer",
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: "action.hover",
        },
      }}
    >
      <Avatar
        sx={{
          width: 40,
          height: 40,
          backgroundColor: "grey.300",
          mr: 2,
        }}
      >
        #
      </Avatar>

      <Box flexGrow={1}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: isSelectedChat ? 600 : 400,
            color: isSelectedChat ? "primary.main" : "text.primary",
          }}
          noWrap
        >
          {channel.name}
        </Typography>
      </Box>

      {unRead && (
        <Badge badgeContent={unRead} color="primary" sx={{ ml: "auto" }} />
      )}
    </Box>
  );
};

export default ChatChannel;
