import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MessagesTypes } from "../../../data/messages";

interface RepliedMessageProps {
  isFromMe: boolean;
  message: MessagesTypes;
  fullName: string;
}

function RepliedMessage({ message, fullName }: RepliedMessageProps) {
  const { t } = useTranslation();

  return (
    <Box mb={2} pl={2} borderLeft="4px solid" borderColor="primary.main">
      <Typography variant="subtitle2">
        {message.meta.sender === fullName ? t("You") : fullName}
      </Typography>
      {message.replyOf?.text && (
        <Typography variant="body2">{message.replyOf.text}</Typography>
      )}
      {(message.replyOf?.image || message.replyOf?.attachments) && (
        <Typography variant="body2">
          {message.replyOf.attachments && !message.replyOf.newimage
            ? `${message.replyOf.attachments.length} ${t("Files")}`
            : ""}
          {message.replyOf.newimage && !message.replyOf.attachments
            ? `${message.replyOf.newimage.length} ${t("Images")}`
            : ""}
          {message.replyOf.newimage && message.replyOf.attachments
            ? `${message.replyOf.attachments.length} ${t("Files")} & ${message.replyOf.newimage.length} ${t("Images")}`
            : ""}
        </Typography>
      )}
    </Box>
  );
}

export default RepliedMessage;
