import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  Box,
  IconButton,
  useMediaQuery,
  Theme,
  Tooltip,
} from "@mui/material";
import { useRedux } from "../../hooks";
import { changeTab } from "../../redux/actions";
import { TABS } from "../../constants";
import logo from "../../assets/images/logo-icon-branca.svg";
import messageIcon from "../../assets/images/message-icon.png";
import { useTranslation } from "react-i18next";
import ProfileDropdownMenu from "../../components/ProfileDropdownMenu";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

import { getMenuItems } from "./menu";
import FloatingSupportIcon from "../../components/FloatingSupportIcon";

const Logo = ({ to, isMobile }: { to: string; isMobile: boolean }) => (
  <Link to={to}>
    <IconButton>
      {isMobile ? (
        <HomeIcon sx={{ color: "white", fontSize: "30px" }} />
      ) : (
        <img src={logo} width="28px" alt="Logo" />
      )}
    </IconButton>
  </Link>
);

interface SideMenuProps {
  onChangeLayoutMode: (value: any) => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ onChangeLayoutMode }) => {
  const { dispatch, useAppSelector } = useRedux();
  const { t } = useTranslation();
  const menuItems = getMenuItems(t);
  const { activeTab } = useAppSelector(state => ({
    activeTab: state.Layout.activeTab,
  }));

  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<TABS>(TABS.USERS);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );

  useEffect(() => {
    setSelectedTab(activeTab);
  }, [activeTab]);

  const onChangeTab = (id: TABS) => {
    setSelectedTab(id);
    dispatch(changeTab(id));
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <Box
      sx={{
        minWidth: { xs: "100%", md: "4.6875rem" },
        maxWidth: { md: "4.6875rem" },
        height: { xs: "3.75rem", md: "100vh" },
        backgroundColor: "#333",
        display: "flex",
        flexDirection: { xs: "row", md: "column" },
        zIndex: 9,
        borderRight: { md: "1px solid #000" },
        position: { xs: "fixed", md: "relative" },
        bottom: { xs: 0, md: "auto" },
        borderTop: { xs: "1px solid #000", md: "none" },
        alignItems: "center",
        justifyContent: isMobile ? "space-around" : "flex-end",
        padding: isMobile ? "1.5rem" : "1.25rem",
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginLeft: 0,
            marginRight: 0,
          }}
        >
          <Logo to="/" isMobile={isMobile} />
        </Box>
      )}

      {/* Desktop Icons */}
      {!isMobile && (
        <>
          {/* Home Icon */}
          <Box
            sx={{
              height: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <Tooltip title={t("Home")} placement="right" arrow>
              <ListItem
                button
                component={Link}
                to="/dashboard"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0.25rem",
                  marginX: "auto",
                  marginY: "1.5rem",
                  borderRadius: "0.5rem",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#222",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: "white",
                    fontSize: "1.5rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i className="bx bx-home" style={{ fontSize: 28 }}></i>
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          </Box>

          {/* Chat Icon
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginY: "0.5rem",
            }}
          >
            <Tooltip title={t("Chat")} placement="right" arrow>
              <ListItem
                button
                component={Link}
                to="/chart"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0.25rem",
                  marginX: "auto",
                  borderRadius: "0.5rem",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#222",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: "white",
                    fontSize: 24,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src={messageIcon} alt="Chat Icon" width="24px" />
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          </Box> */}

          {/* Download Icon */}
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    display: "flex",*/}
          {/*    justifyContent: "center",*/}
          {/*    alignItems: "center",*/}
          {/*    width: "100%",*/}
          {/*    marginY: "0.5rem",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Tooltip title={t("Download")} placement="right" arrow>*/}
          {/*    <ListItem*/}
          {/*      button*/}
          {/*      component={Link}*/}
          {/*      to="/download"*/}
          {/*      sx={{*/}
          {/*        justifyContent: "center",*/}
          {/*        alignItems: "center",*/}
          {/*        padding: "0.25rem",*/}
          {/*        marginX: "auto",*/}
          {/*        borderRadius: "0.5rem",*/}
          {/*        transition: "background-color 0.3s ease",*/}
          {/*        "&:hover": {*/}
          {/*          backgroundColor: "#222",*/}
          {/*        },*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <ListItemIcon*/}
          {/*        sx={{*/}
          {/*          color: "white",*/}
          {/*          fontSize: "1.5rem",*/}
          {/*          display: "flex",*/}
          {/*          justifyContent: "center",*/}
          {/*          alignItems: "center",*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <i className="bx bx-download" style={{ fontSize: 28 }}></i>*/}
          {/*      </ListItemIcon>*/}
          {/*    </ListItem>*/}
          {/*  </Tooltip>*/}
          {/*</Box>*/}
        </>
      )}

      {/* Mobile Icons */}

      {/*<ListItem*/}
      {/*  button*/}
      {/*  component={Link}*/}
      {/*  to="/download"*/}
      {/*  sx={{*/}
      {/*    justifyContent: "center",*/}
      {/*    alignItems: "center",*/}
      {/*    padding: "0.25rem",*/}
      {/*    borderRadius: "0.5rem",*/}
      {/*    "&:hover": {*/}
      {/*      backgroundColor: "#222",*/}
      {/*    },*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <ListItemIcon*/}
      {/*    sx={{*/}
      {/*      color: "white",*/}
      {/*      fontSize: "1.5rem",*/}
      {/*      display: "flex",*/}
      {/*      justifyContent: "center",*/}
      {/*      alignItems: "center",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <i className="bx bx-download" style={{ fontSize: 28 }}></i>*/}
      {/*  </ListItemIcon>*/}
      {/*</ListItem>*/}

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: { xs: "row", md: "column" },
          justifyContent: isMobile ? "space-around" : "flex-end",
          alignItems: "center",
          width: "50%",
        }}
      >
        <List
          sx={{
            padding: 0,
            display: "flex",
            flexDirection: { xs: "row", md: "column" },
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",
            gap: isMobile ? "0.5rem" : "0.5rem",
          }}
        >
          {isMobile && (
            <Box
              sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
            >
              <Tooltip title="Dashboard" placement="right" arrow>
                <IconButton
                  component={Link}
                  to="/dashboard"
                  sx={{
                    color: "white",
                    padding: "0.25rem",
                    borderRadius: "0.5rem",
                    fontSize: "1.5rem",
                    "&:hover": {
                      backgroundColor: "#222",
                    },
                  }}
                >
                  <i className="bx bx-home" style={{ fontSize: 28 }}></i>
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
            <FloatingSupportIcon />
          </Box>
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
            <ProfileDropdownMenu />
          </Box>
        </List>
      </Box>
    </Box>
  );
};

export default SideMenu;
