import React from "react";
import {
  Box,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { FormProvider, Controller } from "react-hook-form";
import axios from "axios";
import { URLS } from "../../constants";
import CustomTextField from "./CustomTextField";
import { useEmployeeForm } from "../../hooks/useEmployeeForm";
import { CustomStepConnector, CustomStepIcon } from "./CustomStepConnector";
import { useTranslation } from "react-i18next";

interface EmployeeRegisterProps {
  onSuccess: () => void;
  onError: () => void;
}

const EmployeeRegister: React.FC<EmployeeRegisterProps> = ({ onSuccess, onError }) => {
  const { methods, steps, activeStep, handleNext, handleBack } =
    useEmployeeForm();
  const { t } = useTranslation();

  const handleSubmit = async (data: any) => {
    try {
      const response = await axios.post(
        `${URLS.CLIENT_URL}/api/signup`,
        data,
      );
      console.log("Cadastro de colaborador realizado:", response.data);
      onSuccess();
    } catch (error) {
      console.error("Erro no cadastro de colaborador:", error);
      onError();
    }
  };

  return (
    <Box
      sx={{ width: "100%", maxHeight: "80vh", overflowY: "auto", padding: 2 }}
    >
      <Typography variant="h5" align="center" gutterBottom>
        {t("employeeRegister.title")}
      </Typography>
      <Stepper
        activeStep={activeStep}
        sx={{ marginBottom: 4 }}
        connector={<CustomStepConnector />}
        alternativeLabel
      >
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              sx={{
                [`& .MuiStepLabel-label`]: {
                  color:
                    activeStep === index ? "#4e3699" : "rgba(0, 0, 0, 0.38)",
                  fontSize: { xs: "0.75rem", md: "1rem" },
                },
                [`& .MuiStepLabel-label.Mui-completed`]: {
                  color: "#4e3699",
                },
              }}
            >
              {`${index + 1}. ${t(`employeeRegister.steps.${index}`)}`}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {activeStep === 0 && (
            <>
              <Controller
                name="firstname"
                control={methods.control}
                defaultValue=""
                rules={{
                  required: t("employeeRegister.fields.firstnameRequired"),
                }}
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("employeeRegister.fields.firstname")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="lastname"
                control={methods.control}
                defaultValue=""
                rules={{
                  required: t("employeeRegister.fields.lastnameRequired"),
                }}
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("employeeRegister.fields.lastname")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="cpf"
                control={methods.control}
                defaultValue=""
                rules={{
                  required: t("employeeRegister.fields.cpfRequired"),
                  pattern: {
                    value: /^\d{11}$/, // CPF deve conter 11 dígitos
                    message: t("employeeRegister.fields.cpfInvalid"),
                  },
                }}
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("employeeRegister.fields.cpf")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
            </>
          )}

          {activeStep === 1 && (
            <>
              <Controller
                name="email"
                control={methods.control}
                defaultValue=""
                rules={{ required: t("employeeRegister.fields.emailRequired") }}
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("employeeRegister.fields.email")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="telephone"
                control={methods.control}
                defaultValue=""
                rules={{
                  required: t("employeeRegister.fields.telephoneRequired"),
                }}
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("employeeRegister.fields.telephone")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
              <Controller
                name="password"
                control={methods.control}
                defaultValue=""
                rules={{
                  required: t("employeeRegister.fields.passwordRequired"),
                }}
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("employeeRegister.fields.password")}
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
            </>
          )}

          {activeStep === 2 && (
            <>
              <FormControl fullWidth margin="normal">
                <InputLabel>{t("employeeRegister.fields.gender")}</InputLabel>
                <Controller
                  name="gender"
                  control={methods.control}
                  defaultValue="female"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t("employeeRegister.fields.gender")}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <InputLabel>{t("employeeRegister.fields.role")}</InputLabel>
                <Controller
                  name="role"
                  control={methods.control}
                  defaultValue="BASIC"
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t("employeeRegister.fields.role")}
                    >
                      <MenuItem value="BASIC">BASIC</MenuItem>
                      <MenuItem value="ADMIN">ADMIN</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>

              <Controller
                name="company_id"
                control={methods.control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CustomTextField
                    {...field}
                    label={t("employeeRegister.fields.company_id")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ""
                    }
                  />
                )}
              />
            </>
          )}

          <Box display="flex" justifyContent="space-between" mt={4}>
            <Button
              variant="outlined"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{ color: "#4e3699", borderColor: "#4e3699" }}
            >
              {t("companyRegister.backButton")}
            </Button>
            <Button
              variant="contained"
              type={activeStep === 3 ? "submit" : "button"}
              sx={{
                backgroundColor: "#4e3699",
                color: "#fff",
                "&:hover": { backgroundColor: "#60bfb7" },
              }}
              onClick={activeStep === 3 ? undefined : handleNext}
            >
              {activeStep === 2
                ? t("companyRegister.submitButton")
                : t("companyRegister.nextButton")}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default EmployeeRegister;
