import React from "react";
import { IconButton, Box, CircularProgress } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import { useTranslation } from "react-i18next";

interface EndButtonsProps {
  onSubmit: () => void;
  disabled: boolean;
}

const EndButtons = ({ onSubmit, disabled }: EndButtonsProps) => {
  const { t } = useTranslation();

  return (
    <Box className="chat-input-links">
      <IconButton onClick={onSubmit} disabled={disabled}>
        {disabled ? <MicIcon /> : <SendIcon sx={{ color: "#4e3699" }} />}
      </IconButton>
    </Box>
  );
};

export default EndButtons;
