export interface ContactTypes {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  location: string;
  profileImage?: string | null;
}

let contacts: ContactTypes[] = [
  {
    id: 1,
    firstName: "John",
    lastName: "Doe",
    email: "johndoe@example.com",
    location: "New York",
    profileImage: null,
  },
  {
    id: 2,
    firstName: "Jane",
    lastName: "Smith",
    email: "janesmith@example.com",
    location: "California",
    profileImage: null,
  },
];

const onChangeContacts = (newData: Array<ContactTypes>) => {
  contacts = newData;
};

export { contacts, onChangeContacts };
