import React, { useState } from "react";
import {
  Grid,
  Button,
  ButtonGroup,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Slide,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RegisterLayoutWrapper from "../../components/RegisterLayoutWrapper";
import "../../assets/scss/custom.scss";
import CompanyRegister from "../../components/register/CompanyRegister";
import EmployeeRegister from "../../components/register/EmployeeRegister";

const Register: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const handleButtonClick = (newValue: number) => {
    if (selectedTab === newValue && openModal) {
      setOpenModal(false);
    } else {
      setSelectedTab(newValue);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSuccess = (type: string) => {
    toast.success(t(`${type}.success`));
    handleCloseModal();
  };

  const handleError = (type: string) => {
    toast.error(t(`${type}.error`));
  };


  const renderForm = () => {
    if (selectedTab === 0) {
      return (
        <CompanyRegister
          onSuccess={() => handleSuccess("companyRegister")}
          onError={() => handleError("companyRegister")}
        />
      );
    } else if (selectedTab === 1) {
      return (
        <EmployeeRegister
          onSuccess={() => handleSuccess("employeeRegister")}
          onError={() => handleError("employeeRegister")}
        />
      );
    }
    return null;
  };

  return (
    <RegisterLayoutWrapper>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Card
            className="custom-scrollbar"
            sx={{
              padding: 4,
              width: "100%",
              boxSizing: "border-box",
              border: "none",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <ButtonGroup
              fullWidth
              sx={{
                marginBottom: 3,
              }}
            >
              <Button
                variant={selectedTab === 0 ? "contained" : "outlined"}
                onClick={() => handleButtonClick(0)}
                sx={{
                  color: selectedTab === 0 ? "#fff" : "#4e3699",
                  backgroundColor:
                    selectedTab === 0 ? "#4e3699" : "transparent",
                  borderColor: "#4e3699",
                  "&:hover": {
                    backgroundColor:
                      selectedTab === 0 ? "#3b2a73" : "rgba(96, 191, 183, 0.1)",
                    borderColor: "#4e3699",
                  },
                }}
              >
                {t("registerPage.companyTab")}
              </Button>
              <Button
                variant={selectedTab === 1 ? "contained" : "outlined"}
                onClick={() => handleButtonClick(1)}
                sx={{
                  color: selectedTab === 1 ? "#fff" : "#4e3699",
                  backgroundColor:
                    selectedTab === 1 ? "#4e3699" : "transparent",
                  borderColor: "#4e3699",
                  "&:hover": {
                    backgroundColor:
                      selectedTab === 1 ? "#3b2a73" : "rgba(96, 191, 183, 0.1)",
                    borderColor: "#4e3699",
                  },
                }}
              >
                {t("registerPage.employeeTab")}
              </Button>
            </ButtonGroup>
          </Card>
        </Grid>
      </Grid>

      {/* Modal for displaying the form */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
        TransitionComponent={Slide}
        transitionDuration={300}
      >
        <DialogTitle>
          {selectedTab === 0
            ? t("registerPage.companyTab")
            : t("registerPage.employeeTab")}
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div
            style={{ maxHeight: "70vh", overflowY: "auto" }}
            className="custom-scrollbar"
          >
            {renderForm()}
          </div>
        </DialogContent>
      </Dialog>

      {/* Place ToastContainer here */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 9999 }}
      />
    </RegisterLayoutWrapper>
  );
};

export default Register;
