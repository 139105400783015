import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LINKS } from "../constants/settings";
import logo from "../assets/images/logo-branca.svg";

interface RegisterLayoutWrapperProps {
  children: React.ReactNode;
}

const RegisterLayoutWrapper: React.FC<RegisterLayoutWrapperProps> = ({
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className="auth-bg d-flex flex-column min-vh-100">
      <Container
        fluid
        className="p-0 h-100 d-flex flex-column justify-content-center align-items-center"
      >
        <Row className="flex-grow-1 w-100 d-flex justify-content-center align-items-center">
          <Col
            md={5}
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ zIndex: 1 }}
          >
            <div className="welcome-section text-center text-md-start">
              <div className="logo-section mb-4">
                <img
                  src={logo}
                  alt="Logo"
                  className="logo-img"
                  style={{ height: "80px" }}
                />
              </div>
              <div className="welcome">
                <h1 style={{ fontSize: "2.5rem" }}>
                  {" "}
                  {t("registerLayout.welcomeTo")}{" "}
                  <span className="highlight" style={{ color: "#93f090" }}>
                    {" "}
                    {t("registerLayout.ourPlatform")}
                  </span>{" "}
                  <br />
                  {t("registerLayout.registerNow")}{" "}
                  <span className="focus-text" style={{ color: "#93f090" }}>
                    {" "}
                    {t("registerLayout.andJoinUs")}
                  </span>
                </h1>
                <p style={{ fontSize: "1.25rem" }}>
                  {" "}
                  {t("registerLayout.getStartedMessage")}
                </p>
              </div>
            </div>
          </Col>
          <Col
            md={7}
            className="d-flex align-items-center justify-content-center"
            style={{ zIndex: 2 }}
          >
            <div className="authentication-page-content">{children}</div>
          </Col>
        </Row>
        <Row className="w-100">
          <Col
            className="d-flex justify-content-center align-items-center"
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              color: "white",
              textAlign: "center",
            }}
          >
            <Link
              to={LINKS.COMPANY_WEBSITE}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              &copy;It Tech
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RegisterLayoutWrapper;
