import React, { useState, KeyboardEvent, useRef, useEffect } from "react";
import { TextField } from "@mui/material";

interface InputSectionProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
}

const InputSection = ({ value, onChange, onSubmit }: InputSectionProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        onChange(value + "\n");
      } else {
        e.preventDefault();
        console.log("Mensagem enviada:", value);
        onSubmit();
      }
    }

    if ((e.ctrlKey || e.metaKey) && e.key === "b") {
      e.preventDefault();
      setIsBold(!isBold);
    }

    if ((e.ctrlKey || e.metaKey) && e.key === "i") {
      e.preventDefault();
      setIsItalic(!isItalic);
    }
  };

  const getFormattedText = () => {
    let formattedText = value;
    if (isBold) {
      formattedText = `**${formattedText}**`;
    }
    if (isItalic) {
      formattedText = `*${formattedText}*`;
    }
    return formattedText;
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [value]);

  return (
    <TextField
      fullWidth
      variant="outlined"
      placeholder="Enter Message..."
      value={getFormattedText()}
      onChange={e => onChange(e.target.value)}
      onKeyDown={handleKeyDown}
      multiline
      inputRef={textAreaRef}
      maxRows={4}
      sx={{
        backgroundColor: "#f5f5f5",
        borderRadius: "16px",
        paddingLeft: 1,
        overflowY: "auto",
        minHeight: "20px",
        maxHeight: "150px",
        display: "flex",
        alignItems: "flex-end",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "none",
          },
        },
      }}
    />
  );
};

export default InputSection;
