import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { AuthLoginActionTypes } from "./types";
import {
  authLoginApiResponseSuccess,
  authLoginApiResponseError,
  setUserProfile,
  setLoading,
} from "./actions";
import config from "../../../config";


//Include Both Helper File with needed methods
import {
  getFirebaseBackend,
  setLoggeedInUser,
} from "../../../helpers/firebase_helper";
import Cookies from "js-cookie";
import {URLS} from "../../../constants";
import axios, { AxiosResponse } from "axios";


interface LoginResponse {
  apikey: string;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
}

function* loginUser({ payload: { user } }: any) {
  try {
    // const TESTE = "http://connectai.local"
    const response: Response = yield call(
      fetch,
      `${URLS.CLIENT_URL}/api/login`,
      {
        // const response: Response = yield call(fetch, `${TESTE}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      },
    );
    const data: LoginResponse = yield response.json();

    if (!response.ok) {
      if (response.status === 404 || response.status === 401) {
        throw new Error("authNotFound");
      }
      throw new Error("internalServerError");
    }

    // Save the response data in cookies if needed
    if (data.apikey) {
      Cookies.set("api-key", data.apikey, { expires: 7 });
      Cookies.set("companyName", data.companyName, { expires: 7 });
      Cookies.set("userName", `${data.firstName} ${data.lastName}`, {
        expires: 7,
      });
      Cookies.set("email", data.email, { expires: 7 });
      localStorage.setItem("authUser", JSON.stringify(data));
    }

    yield put(
      authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, data),
    );
  } catch (error: any) {
    yield put(authLoginApiResponseError(AuthLoginActionTypes.LOGIN_USER, error.message));
  }
}

function* socialLogin({ payload: { data, type } }: any) {

}

function* logoutUser() {
  try {
    const apiKey = Cookies.get("api-key");
    yield call(axios.post, `${URLS.CLIENT_URL}/api/logout`, null, {
      headers: {
        'Content-Type': 'application/json',
        APIKEY: apiKey,
      },
    });
    localStorage.removeItem("authUser");

    Cookies.remove("api-key");
    Cookies.remove("companyName");
    Cookies.remove("userName");
    Cookies.remove("email");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGOUT_USER, true)
      );
    } else {
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGOUT_USER, true)
      );
    }
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGOUT_USER, error)
    );
  }
}

function* loginSaga() {
  yield takeEvery(AuthLoginActionTypes.LOGIN_USER, loginUser);
  yield takeEvery(AuthLoginActionTypes.LOGOUT_USER, logoutUser);
  yield takeLatest(AuthLoginActionTypes.SOCIAL_LOGIN, socialLogin);
}

export default loginSaga;
