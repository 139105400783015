import Cookies from "js-cookie";

export interface BasicDetailsTypes {
  firstName: string;
  lastName: string;
  title: string;
  description: string;
  fullName: string;
  email: string;
  location: string;
  avatar: string | null;
  coverImage: string | null;
  companyName?: string;
  userName?: string;
}

export interface MediaListItemTypes {
  id: number;
  url: string | null;
}
export interface MediaTypes {
  total: number;
  list: MediaListItemTypes[];
}

export interface AttachedfileTypes {
  total: number;
  list: AttachedfileItemTypes[];
}

export interface AttachedfileItemTypes {
  id: number;
  fileName: string;
  size: string;
  downloadUrl: string;
  icon: string;
}
export interface ProfileDetailsTypes {
  basicDetails: BasicDetailsTypes;
  media: MediaTypes;
  attachedFiles: AttachedfileTypes;
}

const profileDetails: ProfileDetailsTypes = {
  basicDetails: {
    firstName: "",
    lastName: "",
    title: Cookies.get("companyName") as string,
    description: "Simple language grammar.",
    fullName: Cookies.get("userName") as string,
    email: "admin@themesbrand.com",
    location: "California, USA",
    avatar: null, // Set to null or a default placeholder
    coverImage: null,
  },
  media: {
    total: 17,
    list: Array(17).fill({ id: 1, url: null }), // Empty URLs
  },
  attachedFiles: {
    total: 4,
    list: [
      {
        id: 1,
        fileName: "design-phase-1-approved.pdf",
        size: "12.5 MB",
        downloadUrl: "#",
        icon: "bx bx-file",
      },
    ],
  },
};

export const setProfileDetails = (details: Partial<BasicDetailsTypes>) => {
  profileDetails.basicDetails = { ...profileDetails.basicDetails, ...details };
};

let user: any = localStorage.getItem("authUser");
user = JSON.parse(user);

const myData = {
  uid: user && user.uid ? user.uid : 1,
  username: "admin",
  role: "admin",
  password: "123456",
  email: profileDetails.basicDetails.email,
  profileImage: profileDetails.basicDetails.avatar,
};

export { profileDetails, myData };
