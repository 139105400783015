import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import Picker from "emoji-picker-react";
import { useTranslation } from "react-i18next";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";

interface StartButtonsProps {
  onToggle: () => void;
  onEmojiClick: any;
  emojiPicker: any;
  setemojiPicker: any;
}

const StartButtons = ({
  onToggle,
  onEmojiClick,
  emojiPicker,
  setemojiPicker,
}: StartButtonsProps) => {
  const { t } = useTranslation();

  return (
    <div className="chat-input-links">
      <div className="links-list-item">
        <Tooltip title={t("More")} placement="top">
          <IconButton onClick={onToggle}>
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>
      </div>

      {emojiPicker && (
        <Picker onEmojiClick={onEmojiClick} width={350} height={382} />
      )}

      <div className="links-list-item">
        <Tooltip title={t("Emoji")} placement="top">
          <IconButton onClick={() => setemojiPicker(!emojiPicker)}>
            <EmojiEmotionsIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default StartButtons;
