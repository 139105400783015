import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

export const useEmployeeForm = () => {
  const schema = yup.object().shape({
    firstname: yup.string().required("Primeiro nome é obrigatório"),
    lastname: yup.string().required("Sobrenome é obrigatório"),
    cpf: yup.string().required("CPF é obrigatório").length(11, "CPF inválido"),
    email: yup
      .string()
      .email("E-mail inválido")
      .required("E-mail é obrigatório"),
    telephone: yup
      .string()
      .required("Telefone é obrigatório")
      .min(10, "Número inválido"),
    password: yup
      .string()
      .required("Senha é obrigatória")
      .min(6, "Senha muito curta"),
    gender: yup.string().required("Gênero é obrigatório"),
    role: yup.string().required("Função é obrigatória"),
    company_id: yup.string().required("Selecione uma empresa"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const [activeStep, setActiveStep] = useState(0);

  const steps = ["Dados Pessoais", "Informações de Contato", "Credenciais"];

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const onSubmit = async (data: any) => {
    try {
      const response = await axios.post("/api/user", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      });
      console.log("Colaborador cadastrado com sucesso", response.data);
    } catch (error) {
      console.error("Erro ao cadastrar colaborador", error);
    }
  };

  return { methods, steps, activeStep, handleNext, handleBack, onSubmit };
};

export default useEmployeeForm;
