import React, { useEffect, useState } from "react";
import { Box, FormControl, Alert } from "@mui/material";
import EndButtons from "./EndButtons";
import InputSection from "./InputSection";
import MoreMenu from "./MoreMenu";
import Reply from "./Reply";
import StartButtons from "./StartButtons";

// interface
import {
  MessagesTypes,
  AttachmentTypes,
  ImageTypes,
} from "../../../../data/messages";

interface IndexProps {
  onSend: (data: MessagesTypes) => void;
  replyData: null | MessagesTypes | undefined;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  chatUserDetails: any;
  isChannel: boolean;
}

const Index = ({
  onSend,
  replyData,
  onSetReplyData,
  chatUserDetails,
  isChannel,
}: IndexProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [text, setText] = useState<string>("");
  const [images, setImages] = useState<ImageTypes[] | undefined>(undefined);
  const [files, setFiles] = useState<AttachmentTypes[] | undefined>(undefined);

  const [emojiPicker, setemojiPicker] = useState<boolean>(false);
  const [emojiArray, setemojiArray] = useState<any>("");

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onChangeText = (value: string) => {
    setText(value);
  };

  const onSelectImages = (images: Array<any>) => {
    setImages(
      images.map((i: any, key: number) => ({
        id: key + 1,
        downloadLink: URL.createObjectURL(i),
      })),
    );
  };

  const onSelectFiles = (files: Array<any>) => {
    setFiles(
      files.map((f: any, key: number) => ({
        id: key + 1,
        name: f.name,
        downloadLink: URL.createObjectURL(f),
        desc: f.size,
      })),
    );
  };

  useEffect(() => {
    setDisabled(
      !(text || (images && images.length) || (files && files.length)),
    );
  }, [text, images, files]);

  const onEmojiClick = (event: any) => {
    setemojiArray([...emojiArray, event.emoji]);
    setText((text || "") + event.emoji);
  };

  const onSubmit = () => {
    const data: MessagesTypes = {
      mId: Date.now(),
      text,
      time: new Date().toISOString(),
      meta: {
        receiver: chatUserDetails.id,
        sender: "You",
        sent: true,
        received: false,
        read: false,
      },
      attachments: files,
      image: images,
    };

    setText("");
    setImages(undefined);
    setFiles(undefined);
    setemojiPicker(false);

    onSend(data);
  };

  const onClearMedia = () => {
    setImages(undefined);
    setFiles(undefined);
  };

  return (
    <Box
      className="chat-input-section"
      sx={{
        position: "sticky",
        bottom: 0,
        backgroundColor: "#fff",
        zIndex: 10,
        padding: "8px",
      }}
    >
      <FormControl
        component="form"
        onSubmit={(e: any) => {
          e.preventDefault();
          onSubmit();
        }}
        fullWidth
        sx={{
          position: "relative",
          zIndex: 20,
        }}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <StartButtons
            onToggle={onToggle}
            onEmojiClick={onEmojiClick}
            setemojiPicker={setemojiPicker}
            emojiPicker={emojiPicker}
          />
          <Box sx={{ zIndex: 30, width: "100%" }}>
            <InputSection
              value={text}
              onChange={onChangeText}
              onSubmit={onSubmit}
            />
          </Box>
          <EndButtons onSubmit={onSubmit} disabled={disabled} />
        </Box>
      </FormControl>

      {(images && images.length) || (files && files.length) ? (
        <Alert
          severity="info"
          onClose={onClearMedia}
          sx={{
            borderRadius: "12px",
            marginTop: "8px",
            padding: "8px 16px",
            position: "absolute",
            bottom: "60px",
            width: "100%",
            zIndex: 5,
          }}
        >
          {images && !files && `You have selected ${images.length} images`}
          {files && !images && `You have selected ${files.length} files`}
          {files &&
            images &&
            `You have selected ${files.length} files & ${images.length} images`}
        </Alert>
      ) : null}

      <MoreMenu
        isOpen={isOpen}
        onSelectImages={onSelectImages}
        onSelectFiles={onSelectFiles}
        onToggle={onToggle}
      />

      <Reply
        reply={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      />
    </Box>
  );
};

export default Index;
