import React from "react";
import { Collapse, Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { MessagesTypes } from "../../../../data/messages";

// interface  ReplyProps
interface ReplyProps {
  reply: null | MessagesTypes | undefined;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  chatUserDetails: any;
}

const Reply = ({ reply, onSetReplyData, chatUserDetails }: ReplyProps) => {
  const { t } = useTranslation();

  const isReplyFromMe = reply && reply.meta?.sender === chatUserDetails?.userId;

  const onClose = () => {
    onSetReplyData(null);
  };

  return (
    <Collapse isOpen={!!reply} className="chat-input-collapse replyCollapse">
      <Card className="mb-0">
        <CardBody className="py-3">
          <div className="replymessage-block mb-0 d-flex align-items-start">
            <div className="flex-grow-1">
              <h5 className="conversation-name">
                {isReplyFromMe ? t("You") : reply?.meta?.userData?.firstName}
              </h5>
              {reply?.text && <p className="mb-0">{reply?.text}</p>}
            </div>
            <div className="flex-shrink-0">
              <button
                type="button"
                className="btn btn-sm btn-link mt-n2 me-n3 font-size-18"
                onClick={onClose}
              >
                <i className="bx bx-x align-middle"></i>
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
    </Collapse>
  );
};

export default Reply;
