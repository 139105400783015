import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { mockConversations } from "../../../data/mockData";

// hooks
import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes } from "../../../data/messages";
import { ContactTypes, ConversationTypes } from "../../../data/index";

interface IndexProps {
  isChannel: boolean;
  contact?: ContactTypes;
  conversation: ConversationTypes;
}

const Index = ({ isChannel, contact, conversation }: IndexProps) => {
  const { userProfile } = useProfile();
  const [messages, setMessages] = useState<MessagesTypes[]>([]);
  const [replyData, setReplyData] = useState<
    null | MessagesTypes | undefined
  >();

  const fetchMessages = (chatId: string | number) => {
    const conversation = mockConversations.find(conv => conv.userId === chatId);

    if (conversation) {
      const mappedMessages = conversation.messages.map(
        (msg: MessagesTypes) => ({
          ...msg,
          time: new Date(msg.time).toISOString(),
        }),
      );

      setMessages(mappedMessages.reverse());
    } else {
      console.error("Conversa não encontrada para o chatId:", chatId);
    }
  };

  useEffect(() => {
    if (contact?.id) {
      fetchMessages(contact.id);
    }
  }, [contact]);

  const onSend = (data: MessagesTypes) => {
    if (!contact?.id) {
      console.error("ID do contato não definido");
      return;
    }

    const newMessage: MessagesTypes = {
      mId: new Date().getTime(),
      text: data.text || "",
      time: new Date().toISOString(),
      image: data.image || [],
      attachments: data.attachments || [],
      meta: {
        receiver: contact.id,
        sender: "You",
        sent: true,
        received: false,
        read: true,
      },
      replyOf: replyData || undefined,
    };

    setMessages(prevMessages => [...prevMessages, newMessage]);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <UserHead
        chatUserDetails={contact}
        pinnedTabs={[]}
        onOpenUserDetails={() => {}}
        onDelete={() => {}}
        isChannel={isChannel}
        onToggleArchive={() => {}}
      />

      {/* Este Box controla o layout do corpo do chat */}
      <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
        {/* Este Box será responsável pela área de mensagens */}
        <Box
          flexGrow={1}
          sx={{ maxHeight: "calc(100vh - 150px)", overflowY: "auto" }}
        >
          <Conversation
            chatUserConversations={{
              conversationId: String(contact?.id),
              messages, // Passa `messages` para o componente Conversation
            }}
            chatUserDetails={contact}
            onDelete={() => {}}
            onSetReplyData={setReplyData}
            isChannel={isChannel}
          />
        </Box>

        {/* O campo de entrada de mensagens sempre visível, fora da área de scroll */}
        <ChatInputSection
          onSend={onSend}
          replyData={replyData}
          onSetReplyData={setReplyData}
          chatUserDetails={contact}
          isChannel={isChannel}
        />
      </Box>
    </Box>
  );
};

export default Index;
