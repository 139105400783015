import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  useMediaQuery,
  Box,
  Typography,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import ChatList from "./ChatList";
import Archive from "./Archive";
import AppSimpleBar from "../../../components/AppSimpleBar";
import InviteContactModal from "../../../components/InviteContactModal";
import ContactModal from "../../../components/ContactModal";
import ChatMessages from "../ConversationUser/index";

import { mockUsers, mockConversations } from "../../../data/mockData";
import {
  conversations,
  archiveChats,
  chatList as initialChatList,
} from "../../../data";
import { CHATS_TABS, STATUS_TYPES, URLS } from "../../../constants";

const Index = () => {
  const { t } = useTranslation();
  const [selectedChat, setSelectedChat] = useState<number | string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenAddContact, setIsOpenAddContact] = useState<boolean>(false);
  const [active, setActive] = useState(CHATS_TABS.DEFAULT);
  const [showChatList, setShowChatList] = useState(true);
  const [chatList, setChatList] = useState(mockUsers);
  const [searchTerm, setSearchTerm] = useState("");
  const isMobile = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    const fetchChats = async () => {
      const apiKey = Cookies.get("api-key");
      if (!apiKey) {
        console.error("API Key não encontrada");
        return;
      }

      try {
        const response = await fetch(`http://localhost:3002/api/get-chats`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            APIKEY: apiKey,
          },
        });

        if (!response.ok) {
          throw new Error(`Erro: ${response.status}`);
        }

        const data = await response.json();
        const chats = data.chats;

        const mappedChats = chats.map((chat: any) => ({
          id: chat.id,
          firstName: chat.name || "",
          lastName: "",
          profileImage: null,
          status: chat.notSpam ? STATUS_TYPES.ACTIVE : STATUS_TYPES.AWAY,
          meta: {
            unRead: chat.commentsCount,
            messagePreview: "",
            lastMessageTime: new Date(
              chat.conversationTimestamp * 1000,
            ).toLocaleTimeString(),
          },
        }));

        setChatList(mappedChats);
      } catch (error) {
        console.error("Erro ao buscar os chats: ", error);
      }
    };

    fetchChats();
  }, []);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const openAddContactModal = () => setIsOpenAddContact(true);
  const closeAddContactModal = () => setIsOpenAddContact(false);

  const onChangeTab = (tab: CHATS_TABS) => {
    setActive(tab);
  };

  const onSelectChat = (id: number | string) => {
    setSelectedChat(id);
    if (isMobile) {
      setShowChatList(false);
    }
  };

  const handleBackToChatList = () => {
    setShowChatList(true);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredChatList = chatList.filter(contact => {
    const fullName = `${contact.firstName} ${contact.lastName}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  const selectedContact = chatList.find(contact => contact.id === selectedChat);
  const selectedConversation = conversations.find(
    conversation => conversation.userId === selectedChat,
  );

  return (
    <Grid container height="100vh">
      {/* Sidebar Chats */}
      {showChatList || !isMobile ? (
        <Grid item xs={12} sm={3.5} sx={{ backgroundColor: "#f7f7f7" }}>
          <Box p={3} display="flex" flexDirection="column" height="100%">
            <Typography variant="h5" mb={2}>
              {t("Chats")}
            </Typography>
            <TextField
              fullWidth
              onChange={handleSearch}
              id="searchChatUser"
              placeholder={t("Search messages or users")}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <AppSimpleBar className="chat-room-list" style={{ flexGrow: 1 }}>
              {active === CHATS_TABS.DEFAULT && (
                <>
                  <ChatList
                    users={filteredChatList}
                    selectedChat={selectedChat}
                    onSelectChat={onSelectChat}
                  />

                  <Typography
                    align="center"
                    variant="body2"
                    component="a"
                    href="#"
                    onClick={() => onChangeTab(CHATS_TABS.ARCHIVE)}
                    sx={{
                      textDecoration: "none",
                      color: "primary.main",
                      mt: 2,
                      display: "block",
                    }}
                  >
                    {t("Archived Contacts")}
                  </Typography>
                </>
              )}

              {active === CHATS_TABS.ARCHIVE && (
                <>
                  <Archive
                    archiveContacts={archiveChats}
                    selectedChat={selectedChat}
                    onSelectChat={onSelectChat}
                  />
                  <Typography
                    align="center"
                    variant="body2"
                    component="a"
                    href="#"
                    onClick={() => onChangeTab(CHATS_TABS.DEFAULT)}
                    sx={{
                      textDecoration: "none",
                      color: "primary.main",
                      mt: 2,
                      display: "block",
                    }}
                  >
                    {t("Chats")}
                  </Typography>
                </>
              )}
            </AppSimpleBar>
          </Box>
        </Grid>
      ) : null}

      {!showChatList || !isMobile ? (
        <Grid item xs={12} sm={8} sx={{ backgroundColor: "#fff" }}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            {isMobile && (
              <IconButton onClick={handleBackToChatList}>
                <ArrowBackIcon />
              </IconButton>
            )}
            {selectedContact ? (
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                overflow="hidden"
              >
                {/* Ajustamos o scroll aqui */}
                <Box
                  id="chat-conversation-list"
                  sx={{
                    flexGrow: 1,
                    maxHeight: "calc(100vh - 10px)",
                    overflowY: "auto",
                  }}
                >
                  <ChatMessages
                    isChannel={false}
                    contact={selectedContact}
                    conversation={
                      selectedConversation || {
                        conversationId: String(selectedContact.id),
                        userId: String(selectedContact.id),
                        messages: [],
                      }
                    }
                  />
                </Box>
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Typography variant="h6">{t("No chat selected")}</Typography>
              </Box>
            )}
          </Box>
        </Grid>
      ) : null}

      {isOpen && (
        <InviteContactModal
          isOpen={isOpen}
          onClose={closeModal}
          onInvite={data => console.log("Contato convidado: ", data)}
        />
      )}

      {isOpenAddContact && (
        <ContactModal
          isOpen={isOpenAddContact}
          onClose={closeAddContactModal}
          onAddContact={contacts =>
            console.log("Contato adicionado: ", contacts)
          }
        />
      )}
    </Grid>
  );
};

export default Index;
