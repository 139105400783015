import React from "react";
import { StepConnector, StepIconProps } from "@mui/material";
import { styled } from "@mui/system";
import Check from "@mui/icons-material/Check";

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  "&.MuiStepConnector-root": {
    color: "#4e3699",
  },
  "& .MuiStepConnector-line": {
    borderColor: "#4e3699",
  },
}));

const CustomStepIconRoot = styled("div")<{
  active: boolean;
  completed: boolean;
}>(({ active, completed }) => ({
  color: completed || active ? "#4e3699" : "#ccc",
  display: "flex",
  height: 22,
  alignItems: "center",
  "& .QontoStepIcon-completedIcon": {
    color: "#60bfb7",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function CustomStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot
      active={!!active}
      completed={!!completed}
      className={className}
    >
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </CustomStepIconRoot>
  );
}

export { CustomStepConnector, CustomStepIcon };
