import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Menu, MenuItem } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import "../assets/scss/custom.scss";

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    handleClose();
  };

  const languages = [
    { code: "en", name: "English", flag: "🇺🇸" },
    { code: "pt", name: "Português", flag: "🇧🇷" },
    { code: "es", name: "Español", flag: "🇪🇸" },
  ];

  return (
    <div className="language-selector-container">
      <IconButton
        onClick={handleClick}
        sx={{ borderRadius: "16px", color: "#333", fontSize: "24px" }}
      >
        <LanguageIcon fontSize="large" style={{ color: "white", opacity: 0.8 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ "& .MuiPaper-root": { borderRadius: "16px" } }}
      >
        {languages.map(language => (
          <MenuItem
            key={language.code}
            selected={language.code === i18n.language}
            onClick={() => changeLanguage(language.code)}
            sx={{ borderRadius: "12px" }}
          >
            <span style={{ marginRight: "10px" }}>{language.flag}</span>
            {language.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguageSelector;
