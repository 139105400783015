import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, IconButton } from "@mui/material";
import { FaWindows, FaLinux, FaApple } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LINKS } from "../../constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/images/logo-branca.svg";
import platformPreview from "../../assets/images/download-icon.png";

const DownloadPage: React.FC = () => {
  const { t } = useTranslation();
  const [os, setOs] = useState<string>("");

  // Função de simulação de OS para testes
  // const simulateOS = (simulatedOS: string) => {
  //   setOs(simulatedOS);
  // };

  useEffect(() => {
    const platform = window.navigator.platform.toLowerCase();
    if (platform.includes("win")) {
      setOs("Windows");
    } else if (platform.includes("mac")) {
      setOs("Mac");
    } else if (platform.includes("linux")) {
      setOs("Linux");
    } else {
      setOs(t("downloadPage_unknownOs"));
    }
  }, [t]);

  useEffect(() => {
    const hasSeenWarning = localStorage.getItem("seenMacLinuxWarning");

    if (!hasSeenWarning && (os === "Mac" || os === "Linux")) {
      toast.info(t("downloadPage_macLinuxComingSoonMessage"));
      localStorage.setItem("seenMacLinuxWarning", "true");
    }
  }, [os, t]);

  const handleLinkClick = (osType: string, event: React.MouseEvent) => {
    if (osType === "Mac" || osType === "Linux") {
      event.preventDefault();
      toast.info(t("downloadPage_macLinuxComingSoonMessage"));
    }
  };

  const downloadLinks: { [key: string]: string } = {
    Windows: "https://connectai.digital/ConnectAI.exe",
    Mac: "https://connectai.digital/ConnectAI.dmg",
    Linux: "https://connectai.digital/ConnectAI.tar.gz",
  };

  const renderIcon = () => {
    if (os === "Windows") {
      return <FaWindows style={{ marginRight: 8 }} />;
    } else if (os === "Linux") {
      return <FaLinux style={{ marginRight: 8 }} />;
    } else if (os === "Mac") {
      return <FaApple style={{ marginRight: 8 }} />;
    }
    return null;
  };

  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #4e3699 45%, #60bfb7 100%)",
        color: "white",
        minHeight: "100vh",
        width: "100%",
        p: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {/*/!* Botões para simular OS *!/*/}
      {/*<Box sx={{ position: "absolute", top: 16, right: 16 }}>*/}
      {/*  <Button onClick={() => simulateOS("Windows")}>Simular Windows</Button>*/}
      {/*  <Button onClick={() => simulateOS("Mac")}>Simular Mac</Button>*/}
      {/*  <Button onClick={() => simulateOS("Linux")}>Simular Linux</Button>*/}
      {/*</Box>*/}

      <Grid container spacing={4} alignItems="center">
        {/* empty column to put some space to the margin*/}
        <Grid item xs={false} md={1} />

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >
          <Typography variant="h4" gutterBottom style={{ color: "#ffffff" }}>
            {os === "Mac" || os === "Linux"
              ? t("downloadPage_unavailable", { os })
              : t("downloadPage_greeting")}
          </Typography>

          <Button
            style={{ color: "#000" }}
            variant="contained"
            color="primary"
            size="large"
            sx={{
              mt: 3,
              mb: 2,
              width: "100%",
              maxWidth: "300px",
              backgroundColor: "#93f090",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "#7cbf75",
              },
            }}
            href={downloadLinks[os]}
            disabled={os === "Mac" || os === "Linux"} // Desabilitar para Mac e Linux
          >
            {renderIcon()}
            {t("downloadPage_downloadButton", { os })}
          </Button>

          <Typography variant="body2">
            {t("downloadPage_availableFor")}{" "}

            <Link
              to="#"
              onClick={(e) => handleLinkClick("Mac", e)}
              style={{ color: "#93f090", fontWeight: "bold" }}
            >
              Mac
            </Link>
            {"/"}
            <Link
              to="#"
              onClick={(e) => handleLinkClick("Linux", e)}
              style={{ color: "#93f090", fontWeight: "bold" }}
            >
              Linux
            </Link>
            .
          </Typography>

          <Typography variant="body2" sx={{ mt: 2 }}>
            {t("downloadPage_afterDownload")}{" "}
            <a
              href={LINKS.WHATSAPP_SUPPORT}
              style={{ color: "#93f090", fontWeight: "bold" }}
            >
              {t("downloadPage_support")}
            </a>
            .
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={platformPreview}
            alt={t("downloadPage_platformPreviewAlt")}
            style={{ maxWidth: "100%", borderRadius: "8px" }}
          />
        </Grid>
      </Grid>

      <IconButton
        component={Link}
        to="/dashboard"
        sx={{
          position: "absolute",
          bottom: 16,
          left: 16,
          color: "white",
        }}
      ></IconButton>

      <ToastContainer position="top-right" autoClose={5000} />
    </Box>
  );
};

export default DownloadPage;
