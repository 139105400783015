import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

export const useCompanyForm = () => {
  const schema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório"),
    fantasy_name: yup.string().required("Nome fantasia é obrigatório"),
    cnpj: yup.string().required("CNPJ é obrigatório").min(14, "CNPJ inválido"),
    area: yup.string().required("Área de atuação é obrigatória"),
    telephone: yup
      .string()
      .required("Telefone é obrigatório")
      .min(10, "Número inválido"),
    email: yup
      .string()
      .email("E-mail inválido")
      .required("E-mail é obrigatório"),
    website: yup.string().url("Deve ser um URL válido"),
    instagram_profile: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._]{1,30}$/,
        "O nome de usuário do Instagram é inválido"
      )
      .nullable(),
    subscription: yup.string().required("Tipo de assinatura é obrigatório"),
    subscription_message: yup.string(),
    address: yup.object().shape({
      zip_code: yup.string().required("CEP é obrigatório"),
      street: yup.string().required("Rua é obrigatória"),
      number: yup.string().required("Número é obrigatório"),
      city: yup.string().required("Cidade é obrigatória"),
      state: yup.string().required("Estado é obrigatório"),
      country: yup.string().required("País é obrigatório"),
    }),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const [activeStep, setActiveStep] = useState(0);

  const steps = ["Dados Básicos", "Endereço", "Assinatura"];

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const onSubmit = async (data: any) => {
    try {
      const response = await axios.post("/api/company", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      });
      console.log("Empresa cadastrada com sucesso", response.data);
    } catch (error) {
      console.error("Erro ao cadastrar empresa", error);
    }
  };

  return { methods, steps, activeStep, handleNext, handleBack, onSubmit };
};