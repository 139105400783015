import React from "react";
import Root from "../pages/Root/index";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import RecoverPassword from "../pages/Authentication/RecoverPassword";
import ChangePassword from "../pages/Authentication/ChangePassword";
import CreateNewPassword from "../../src/pages/Authentication/NewPassword";
import LockScreen from "../pages/Authentication/LockScreen";
import Dashboard from "../pages/Dashboard/index";
import StarterPage from "../pages/StarterPage/index";
import DownloadPage from "../pages/Download/DownloadPage";
import ChatIndex from "../pages/Dashboard/Chats/index";

interface RouteProps {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

const publicRoutes: Array<RouteProps> = [
  { path: "/auth-login", component: <Login /> },
  { path: "/auth-register", component: <Register /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-changepassword/:token", component: <ChangePassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/logout", component: <Logout /> },
];

const privateRoutes: Array<RouteProps> = [
  { path: "/download", component: <DownloadPage /> },
  { path: "/pages-starter", component: <StarterPage /> },
  { path: "/download", component: <DownloadPage /> },
  { path: "/chart", component: <ChatIndex /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/", component: <Root /> },
  { path: "/auth-create-new-password", component: <CreateNewPassword /> },
];

export { publicRoutes, privateRoutes };
