import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Avatar, Box, Typography, Badge } from "@mui/material";

// interface
import { UserTypes } from "../../../data";
import { STATUS_TYPES } from "../../../constants";

interface ChatUserProps {
  user: UserTypes;
  selectedChat: string | number;
  onSelectChat: (id: number | string) => void;
}

const ChatUser = ({ user, selectedChat, onSelectChat }: ChatUserProps) => {
  const extractNumberFromId = (id: string | number) => {
    const stringId = String(id);
    const atIndex = stringId.indexOf("@");
    return atIndex > -1 ? stringId.substring(0, atIndex) : stringId;
  };

  const fullName =
    user.firstName || user.lastName
      ? `${user.firstName || ""} ${user.lastName || ""}`.trim()
      : extractNumberFromId(user.id);

  const shortName =
    user.firstName || user.lastName
      ? `${user.firstName ? user.firstName.charAt(0) : ""}${
          user.lastName ? user.lastName.charAt(0) : ""
        }`
      : extractNumberFromId(user.id).substring(0, 2);

  const colors = [
    "primary.main",
    "error.main",
    "info.main",
    "warning.main",
    "secondary.main",
    "pink.main",
    "purple.main",
  ];
  const colorIndex = Math.floor(Math.random() * colors.length);
  const isOnline = user.status && user.status === STATUS_TYPES.ACTIVE;
  const unRead = user.meta && user.meta.unRead;

  const isSelectedChat: boolean =
    selectedChat && selectedChat === user.id ? true : false;
  const onClick = () => {
    onSelectChat(user.id);
  };

  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "10px 12px",
        backgroundColor: isSelectedChat ? "action.selected" : "transparent",
        cursor: "pointer",
        borderRadius: "8px",
        transition: "background-color 0.3s ease",
        "&:hover": {
          backgroundColor: "action.hover",
        },
        width: "100%",
        maxWidth: "100%",
        boxSizing: "border-box",
      }}
    >
      <Avatar
        alt={fullName}
        src={user.profileImage}
        sx={{
          bgcolor: colors[colorIndex],
          width: 40,
          height: 40,
          position: "relative",
        }}
      >
        {shortName}
      </Avatar>

      {isOnline && (
        <Badge
          color="success"
          variant="dot"
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{ position: "absolute" }}
        />
      )}

      <Box
        ml={2}
        flexGrow={1}
        sx={{
          overflow: "hidden",
          minWidth: 0,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: isSelectedChat ? 600 : 400,
            color: isSelectedChat ? "primary.main" : "text.primary",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
          noWrap
        >
          {fullName}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          noWrap
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
        >
          {user.meta?.messagePreview}
        </Typography>
      </Box>

      {unRead && (
        <Badge
          badgeContent={unRead}
          color="primary"
          sx={{
            ml: "auto",
            mr: 1.5,
          }}
        />
      )}
    </Box>
  );
};

export default ChatUser;
