import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, List, ListItem } from "@mui/material";

// component
import ChatUser from "./ChatUser";
import ChatChannel from "./ChatChannel";

interface ArchiveProps {
  archiveContacts: any;
  selectedChat: string | number;
  onSelectChat: (id: number | string) => void;
}
const Archive = ({
  archiveContacts,
  selectedChat,
  onSelectChat,
}: ArchiveProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        variant="caption"
        sx={{ fontWeight: 500, color: "text.secondary", px: 2, pt: 2 }}
      >
        {t("Archived")}
      </Typography>

      <List disablePadding>
        {archiveContacts.map((chat: any, key: number) => (
          <ListItem key={key} disableGutters>
            {chat.isChannel ? (
              <ChatChannel
                channel={chat}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
              />
            ) : (
              <ChatUser
                user={chat}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
              />
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Archive;
