import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Container as MuiContainer,
  CircularProgress,
  Typography,
} from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../../assets/images/logo-roxa.svg";
import { QrCodeDisplay } from "./QrCodeDisplay";
import "../../../assets/scss/custom.scss";
import { useTranslation } from "react-i18next";
import {
  connectWebSocket,
  handleWebSocketMessage,
  handleWebSocketError,
  StateType,
} from "../../../utils/webSocketUtils";
import { URLS } from "../../../constants";

interface WebhookQrModalProps {
  isOpen: boolean;
  toggle: () => void;
  existingSocket: WebSocket | null;
  qrCode: string | null;
  showQrCode: boolean;
  updateSessions?: () => void;
}

interface WebhookQrModalState extends StateType {}

export const WebhookQrModal: React.FC<WebhookQrModalProps> = ({
  isOpen,
  toggle,
  existingSocket,
  qrCode,
  showQrCode,
  updateSessions,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<WebhookQrModalState>({
    loading: false,
    error: null,
    qrCode: qrCode,
    showQrCode: showQrCode,
    status: null,
    name: "",
    successMessage: null,
    attemptedSubmit: false,
    buttonBlocked: false,
    webSocketLoading: true,
    nameError: null,
  });

  const socket = useRef<WebSocket | null>(existingSocket);

  useEffect(() => {
    if (isOpen) {
      setState(prevState => ({
        ...prevState,
        loading: true,
      }));

      if (!socket.current) {
        socket.current = connectWebSocket(
          `${URLS.WEBSOCKET_URL}/api/ws`,
          event =>
            handleWebSocketMessage(
              event,
              setState,
              t("QR code successfully scanned!"),
            ),
          event =>
            handleWebSocketError(
              event,
              setState,
              t("Failed to connect to the WebSocket. Please try again."),
            ),
          () => {
            console.log("WebSocket connection closed");
            if (updateSessions) {
              updateSessions();
            }
            handleToggle();
          },
        );
      } else {
        socket.current.onmessage = (event: MessageEvent) =>
          handleWebSocketMessage(
            event,
            setState,
            t("QR code successfully scanned!"),
          );
        socket.current.onerror = (event: Event) =>
          handleWebSocketError(
            event,
            setState,
            t("Failed to connect to the WebSocket. Please try again."),
          );
        socket.current.onclose = () => {
          console.log("WebSocket connection closed");
          if (updateSessions) {
            updateSessions();
          }
          handleToggle();
        };
      }
    }
  }, [isOpen, t, updateSessions]);

  const handleToggle = () => {
    toggle();
    setState({
      loading: false,
      error: null,
      qrCode: null,
      showQrCode: false,
      status: null,
      name: "",
      successMessage: null,
      attemptedSubmit: false,
      buttonBlocked: false,
      webSocketLoading: true,
      nameError: null,
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={toggle}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: "20px",
          padding: "20px",
        },
      }}
    >
      <DialogContent dividers>
        <MuiContainer
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div id="logo" style={{ paddingBottom: "10px", marginTop: "20px" }}>
            <img src={logo} alt={t("Logo")} width={"350"} />
          </div>
          <Typography variant="h6" component="h1" style={{ fontSize: "21px" }}>
            {t("Scan_the_QR_code_to_connect")}
          </Typography>
          {state.loading ? (
            <CircularProgress color="secondary" />
          ) : state.showQrCode && state.qrCode ? (
            <QrCodeDisplay qrCode={state.qrCode} />
          ) : state.status === "WORKING" ? (
            <Typography variant="body1">
              {t("Connected Successfully!")}
            </Typography>
          ) : (
            <Typography variant="body1">
              {t("Waiting for QR code...")}
            </Typography>
          )}
          {state.error && (
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <div className="alert alert-danger" role="alert">
                {state.error}
              </div>
            </div>
          )}
        </MuiContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={toggle}
          variant="contained"
          style={{
            backgroundColor: "#4e3699",
            borderRadius: "20px",
            color: "#fff",
          }}
        >
          {t("Close")}
        </Button>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
};
