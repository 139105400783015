import React, { useState } from "react";
import { Button, IconButton, Tooltip, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FaQuestionCircle } from "react-icons/fa";

interface QrCodeDisplayProps {
  qrCode: string;
}

export const QrCodeDisplay: React.FC<QrCodeDisplayProps> = ({ qrCode }) => {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
    >
      <img
        src={qrCode}
        alt="QR Code"
        style={{ width: "200px", height: "200px" }}
      />
      <Tooltip
        title={
          <Box sx={{ padding: "8px" }}>
            <Typography sx={{ color: "#fff" }} variant="h6">
              {t("How to connect on WhatsApp")}
            </Typography>
            <Typography variant="body2" paragraph>
              {t(
                "Follow the instructions below to connect to WhatsApp using the QR code:",
              )}
            </Typography>
            <ol style={{ paddingLeft: "16px", margin: 0 }}>
              <li>{t("Open the WhatsApp app on your phone.")}</li>
              <li>{t("Go to settings and select 'WhatsApp Web/Desktop'.")}</li>
              <li>
                {t("Scan the QR code displayed on the screen with your phone.")}
              </li>
            </ol>
          </Box>
        }
        open={tooltipOpen}
        onClose={() => setTooltipOpen(false)}
        onOpen={() => setTooltipOpen(true)}
        arrow
        placement="top"
      >
        <IconButton
          onClick={toggleTooltip}
          sx={{
            position: "relative",
            bottom: 0,
            right: -120,
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
            color: "#4e3699",
          }}
        >
          <FaQuestionCircle size={20} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
