import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  TextField,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../../assets/images/logo-connect.svg";
import { QrCodeDisplay } from "./QrCodeDisplay";
import "../../../assets/scss/custom.scss";
import Cookies from "js-cookie";
import { URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import {
  ValidateName,
  containsEmoji,
  isNumeric,
  containsSpecialCharacters,
  isValidLength,
} from "../../../utils/validationutils";
import {
  connectWebSocket,
  handleWebSocketMessage,
  handleWebSocketError,
  handleWebSocketClose,
  StateType,
} from "../../../utils/webSocketUtils";

interface AccountNameFormProps {
  isOpen: boolean;
  toggle: () => void;
  updateSessions?: () => void;
}

export const AccountNameForm: React.FC<AccountNameFormProps> = ({
  isOpen,
  toggle,
  updateSessions,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<StateType>({
    name: "",
    loading: false,
    error: null,
    successMessage: null,
    attemptedSubmit: false,
    buttonBlocked: false,
    qrCode: null,
    showQrCode: false,
    webSocketLoading: true,
    status: null,
    nameError: null,
  });

  const socket = useRef<WebSocket | null>(null);

  const URL = `${URLS.CLIENT_URL}/api/starting`;
  const WEBSOCKET_URL = `${URLS.WEBSOCKET_URL}/api/ws`;

  useEffect(() => {
    return () => {
      handleWebSocketClose(socket.current);
    };
  }, []);

  const handleDataSubmission = async () => {
    const apiKeyCookie = Cookies.get("api-key");
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: URL,
      headers: {
        "Content-Type": "application/json",
        APIKEY: apiKeyCookie,
      },
    };

    try {
      await axios.post(URL, { name: state.name }, config);
      setState(prevState => ({
        ...prevState,
        successMessage: t("Your request has been successfully sent!"),
        buttonBlocked: false,
      }));

      socket.current = connectWebSocket(
        WEBSOCKET_URL,
        event =>
          handleWebSocketMessage(
            event,
            setState,
            t("QR code successfully scanned!"),
          ),
        event =>
          handleWebSocketError(event, setState, t("WebSocket error observed")),
        event => {
          console.log("WebSocket connection closed");
          if (updateSessions) {
            updateSessions();
          }
          handleToggle();
        },
      );
    } catch (error) {
      console.error("Error during submission:", error);
      setState(prevState => ({
        ...prevState,
        error: t(
          axios.isAxiosError(error) && error.response?.status === 409
            ? "This name already exists!"
            : "An error occurred. Please try again.",
        ),
        loading: false,
        buttonBlocked: false,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setState(prevState => ({
      ...prevState,
      attemptedSubmit: true,
    }));

    if (
      state.loading ||
      state.buttonBlocked ||
      state.name.trim().length < 3 ||
      containsEmoji(state.name) ||
      isNumeric(state.name)
    ) {
      return;
    }

    const name = state.name.trim();
    const errorMessage = ValidateName(name, t);

    if (errorMessage) {
      setState(prevState => ({
        ...prevState,
        nameError: t(errorMessage),
      }));
      return;
    }

    setState(prevState => ({
      ...prevState,
      loading: true,
      buttonBlocked: true,
      error: null,
    }));

    handleDataSubmission();
  };

  const handleToggle = () => {
    toggle();
    setState({
      name: "",
      loading: false,
      error: null,
      successMessage: null,
      attemptedSubmit: false,
      buttonBlocked: false,
      qrCode: null,
      showQrCode: false,
      webSocketLoading: true,
      status: null,
      nameError: null,
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={toggle}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: { borderRadius: 12 },
      }}
    >
      <DialogTitle>
        <Typography variant="h6" component="span" style={{ color: "#FFFFFF" }}>
          {t("Criar sessão")}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleToggle}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey.500",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Container className="d-flex flex-column align-items-center justify-content-center">
          <div id="logo" style={{ paddingBottom: "20px" }}>
            <img src={logo} alt={t("Logo")} width={"80"} />
          </div>
          <Typography
            variant="h6"
            component="h1"
            align="center"
            style={{ fontSize: "18px", fontWeight: 500, color: "#333" }}
          >
            {t("Enter your new account here")}
          </Typography>
          <p style={{
            fontSize: "11px",
            textAlign: "center",
            opacity: "60%",
            width: "23em",
            margin: "0"
          }}>{t("This name will appear so you know which account is connected.")}</p>
          {state.loading ? (
            <CircularProgress size={80} color="secondary" />
          ) : state.showQrCode && state.qrCode ? (
            <QrCodeDisplay qrCode={state.qrCode} />
          ) : state.status === "WORKING" ? (
            <Typography>{t("Connected Card")}</Typography>
          ) : (
            <>
              {state.successMessage && (
                <Alert severity="success" className="mt-3">
                  {state.successMessage}
                </Alert>
              )}
              <form onSubmit={handleSubmit} className="w-100">
                <TextField
                  label={t("Account Name")}
                  type="text"
                  id="accountName"
                  name="accountName"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder={t("Account Name")}
                  value={state.name}
                  onChange={e =>
                    setState(prevState => ({
                      ...prevState,
                      name: e.target.value,
                      nameError: null,
                    }))
                  }
                  error={
                    state.attemptedSubmit &&
                    (state.name.trim().length < 3 ||
                      containsEmoji(state.name) ||
                      containsSpecialCharacters(state.name) ||
                      !isValidLength(state.name))
                  }
                  helperText={
                    state.attemptedSubmit &&
                    t(
                      "The account name must be at least 3 characters long, cannot contain emojis, or be composed solely of numbers.",
                    )
                  }
                  InputProps={{
                    style: { borderRadius: 8 }, // Rounded input field
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#4E3699", // Purple border when focused
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#333", // Default color
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#4E3699", // Purple label when focused
                    },
                  }}
                />
                <DialogActions className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    disabled={state.loading || state.buttonBlocked}
                    variant="contained"
                    fullWidth
                    style={{
                      backgroundColor: "#4E3699",
                      color: "#FFFFFF",
                      borderRadius: "20px", // Fully rounded button
                      width: "200px", // Fixed width for centered appearance
                      cursor:
                        state.loading || state.buttonBlocked
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    {t("Scan QR Code")}
                  </Button>
                </DialogActions>
              </form>
            </>
          )}
          {state.error && (
            <Alert severity="error" className="mt-3">
              {state.error}
            </Alert>
          )}
        </Container>
      </DialogContent>
      <ToastContainer />
    </Dialog>
  );
};
